{
  "name": "workspace",
  "version": "2.4.2",
  "license": "MIT",
  "scripts": {
    "openapi-ts": "openapi-ts",
    "nxparallel": "nx --parallel=$(($(nproc)/4))",
    "prepare": "husky",
    "compodoc": "node_modules/.bin/compodoc --exportFormat html --hideGenerator --includes docs --includesName Guides --language en-US --name Compodoc --output dist/compodoc/html --theme material --tsconfig tsconfig.base.json",
    "word": "nx run jwriter-word:serve --ssl",
    "word:nightly": "yarn run word --configuration=nightly",
    "word:prod": "yarn run word --configuration=production",
    "questionnaire": "nx run jwriter-questionnaire:serve --ssl",
    "questionnaire:nightly": "yarn run questionnaire --configuration=nightly",
    "questionnaire:prod": "yarn run questionnaire --configuration=production",
    "jwriter-questionnaire-api": "nx run jwriter-questionnaire-api:serve",
    "jwriter-questionnaire-api:generate-api": "yarn openapi-ts -f apps/jwriter-questionnaire-api/openapi-ts.config.ts",
    "tracking": "nx run jit-tracking:serve --ssl",
    "tracking:nightly": "yarn run tracking --configuration=nightly",
    "tracking:prod": "yarn run tracking --configuration=production",
    "jit-tracking-api": "nx run jit-tracking-api:serve",
    "jit-tracking-api:generate-api": "yarn openapi-ts -f apps/jit-tracking-api/openapi-ts.config.ts",
    "core-api": "nx run jwriter-core-api:serve",
    "jwriter-core-api:generate-api": "yarn openapi-ts -f apps/jwriter-core-api/openapi-ts.config.ts",
    "jdata-wrike-api": "nx run jdata-wrike-api:serve",
    "jdata-wrike-api:generate-api": "yarn openapi-ts -f apps/jdata-wrike-api/openapi-ts.config.ts",
    "jwriter-orchestrator-api": "nx run jwriter-orchestrator-api:serve",
    "jwriter-orchestrator-api:generate-api": "yarn openapi-ts -f apps/jwriter-orchestrator-api/openapi-ts.config.ts",
    "financial": "nx run jwriter-financial:serve --ssl",
    "financial:nightly": "yarn run financial --configuration=nightly",
    "financial:prod": "yarn run financial --configuration=production",
    "unit-test": "yarn nxparallel run-many --target=test",
    "unit-test:affected": "yarn nxparallel affected --base=${NX_AFFECTED_BASE:='HEAD~1'} --target=test",
    "lint": "yarn nxparallel run-many --target=lint",
    "lint:affected": "yarn nxparallel affected --base=${NX_AFFECTED_BASE:='HEAD~1'} --target=lint",
    "stylelint": "yarn nxparallel run-many --target=stylelint",
    "stylelint:affected": "yarn nxparallel affected --base=${NX_AFFECTED_BASE:='HEAD~1'} --target=stylelint --configuration=html",
    "build": "yarn nxparallel run-many --target=build",
    "build:dev": "yarn build --configuration=development",
    "build:nightly": "yarn build --configuration=nightly",
    "build:affected": "yarn nxparallel affected --base=${NX_AFFECTED_BASE:='HEAD~1'} --target=build",
    "build:affected:dev": "yarn build:affected --configuration=development",
    "build:affected:nightly": "yarn build:affected --configuration=nightly",
    "extract-version": "grep '\"version\":' package.json | sed 's/,$//' | tr -d ' ' | sed 's/^/{/' | sed 's/$/}/' > dist/apps/jwriter-financial/assets/version.json & grep '\"version\":' package.json | sed 's/,$//' | tr -d ' ' | sed 's/^/{/' | sed 's/$/}/' > dist/apps/jwriter-word/assets/version.json",
    "generate-schema": "yarn nx run jwriter-core-api:generate-schema && yarn nx run jdata-wrike-api:generate-schema && yarn nx run jwriter-questionnaire-api:generate-schema && yarn nx run jit-tracking-api:generate-schema",
    "generate-client": "yarn nx run jwriter-core-api:generate-client && yarn nx run jdata-wrike-api:generate-client && yarn nx run jwriter-questionnaire-api:generate-client && yarn nx run jit-tracking-api:generate-client",
    "inner:concat-schema": "./tools/concat-prisma-schema.sh",
    "inner:db-push": "yarn run inner:concat-schema && yarn run prisma db push",
    "inner:generate-schema": "yarn run inner:concat-schema && yarn run prisma migrate dev",
    "inner:generate-client": "yarn run inner:concat-schema && yarn run prisma generate --generator client",
    "inner:deploy-schema": "yarn run prisma deploy",
    "inner:jwriter-core-api:db-push": "yarn run inner:db-push --schema apps/jwriter-core-api/src/prisma/schema.prisma",
    "inner:jwriter-core-api:create-empty-migration": "yarn run prisma migrate dev --create-only --name core-api --schema apps/jwriter-core-api/src/prisma/schema.prisma",
    "inner:jwriter-core-api:generate-schema": "yarn run inner:generate-schema --name core-api --schema apps/jwriter-core-api/src/prisma/schema.prisma",
    "inner:jwriter-core-api:generate-client": "yarn run inner:generate-client --schema apps/jwriter-core-api/src/prisma/schema.prisma",
    "inner:jwriter-core-api:deploy-schema": "yarn run inner:deploy-schema --name core-api --schema apps/jwriter-core-api/src/prisma/schema.prisma",
    "inner:jwriter-questionnaire-api:db-push": "yarn run inner:db-push --schema apps/jwriter-questionnaire-api/src/prisma/schema.prisma",
    "inner:jwriter-questionnaire-api:create-empty-migration": "yarn run prisma migrate dev --create-only --name questionnaire-api --schema apps/jwriter-questionnaire-api/src/prisma/schema.prisma",
    "inner:jwriter-questionnaire-api:generate-schema": "yarn run inner:generate-schema --name questionnaire-api --schema apps/jwriter-questionnaire-api/src/prisma/schema.prisma",
    "inner:jwriter-questionnaire-api:generate-client": "yarn run inner:generate-client --schema apps/jwriter-questionnaire-api/src/prisma/schema.prisma",
    "inner:jwriter-questionnaire-api:deploy-schema": "yarn run inner:deploy-schema --name questionnaire-api --schema apps/jwriter-questionnaire-api/src/prisma/schema.prisma",
    "inner:jit-tracking-api:db-push": "yarn run inner:db-push --schema apps/jit-tracking-api/src/prisma/schema.prisma",
    "inner:jit-tracking-api:create-empty-migration": "yarn run prisma migrate dev --create-only --name tracking-api --schema apps/jit-tracking-api/src/prisma/schema.prisma",
    "inner:jit-tracking-api:generate-schema": "yarn run inner:generate-schema --name tracking-api --schema apps/jit-tracking-api/src/prisma/schema.prisma",
    "inner:jit-tracking-api:generate-client": "yarn run inner:generate-client --schema apps/jit-tracking-api/src/prisma/schema.prisma",
    "inner:jit-tracking-api:deploy-schema": "yarn run inner:deploy-schema --name tracking-api --schema apps/jit-tracking-api/src/prisma/schema.prisma",
    "inner:jdata-wrike-api:db-push": "yarn run inner:db-push --schema apps/jdata-wrike-api/src/prisma/schema.prisma",
    "inner:jdata-wrike-api:create-empty-migration": "yarn run prisma migrate dev --create-only --name wrike --schema apps/jdata-wrike-api/src/prisma/schema.prisma",
    "inner:jdata-wrike-api:generate-schema": "yarn run inner:generate-schema --name wrike --schema apps/jdata-wrike-api/src/prisma/schema.prisma",
    "inner:jdata-wrike-api:generate-client": "yarn run inner:generate-client --schema apps/jdata-wrike-api/src/prisma/schema.prisma",
    "inner:jdata-wrike-api:deploy-schema": "yarn run inner:deploy-schema --name wrike --schema apps/jdata-wrike-api/src/prisma/schema.prisma"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.5",
    "@angular/cdk": "19.0.4",
    "@angular/common": "19.0.5",
    "@angular/compiler": "19.0.5",
    "@angular/core": "19.0.5",
    "@angular/forms": "19.0.5",
    "@angular/platform-browser": "19.0.5",
    "@angular/platform-browser-dynamic": "19.0.5",
    "@angular/router": "19.0.5",
    "@azure/msal-angular": "^3.1.0",
    "@azure/msal-browser": "^3.28.0",
    "@hubspot/api-client": "^12.0.1",
    "@microsoft/office-js": "^1.1.99",
    "@microsoft/office-js-helpers": "^1.0.2",
    "@nestjs/axios": "^3.1.3",
    "@nestjs/cli": "^10.4.9",
    "@nestjs/common": "^10.4.15",
    "@nestjs/config": "^3.3.0",
    "@nestjs/core": "10.4.15",
    "@nestjs/jwt": "^10.1.1",
    "@nestjs/passport": "^10.0.2",
    "@nestjs/platform-express": "^10.4.15",
    "@nestjs/swagger": "^8.1.0",
    "@ngrx/component": "19.0.0",
    "@ngrx/effects": "19.0.0",
    "@ngrx/entity": "19.0.0",
    "@ngrx/operators": "19.0.0",
    "@ngrx/router-store": "19.0.0",
    "@ngrx/store": "19.0.0",
    "@ngrx/store-devtools": "19.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@nx/devkit": "20.3.0",
    "@primeng/themes": "^19.0.2",
    "@prisma/client": "^6.1.0",
    "@sentry/angular-ivy": "7.120.2",
    "@swc/helpers": "0.5.15",
    "angular-mentions": "^1.5.0",
    "axios": "1.7.9",
    "chart.js": "^4.4.7",
    "class-transformer": "^0.5.1",
    "class-validator": "^0.14.1",
    "currency.js": "^2.0.4",
    "diff-match-patch-ts": "^0.6.0",
    "flat": "6.0.1",
    "immer": "^10.0.3",
    "joi": "^17.13.3",
    "json-2-csv": "^5.5.7",
    "jwks-rsa": "^3.0.1",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "luxon": "^3.5.0",
    "mathjs": "^14.0.1",
    "ngx-diff": "^10.0.0",
    "ngx-float-ui": "^19.0.0",
    "object-hash": "^3.0.0",
    "office-ui-fabric-js": "^1.5.0",
    "openai": "^4.77.0",
    "parse5": "^7.2.1",
    "passport": "^0.7.0",
    "passport-azure-ad": "^4.3.5",
    "passport-jwt": "^4.0.1",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "17.18.2",
    "puppeteer": "^23.11.1",
    "reflect-metadata": "^0.2.1",
    "rxjs": "~7.8.0",
    "stream-browserify": "^3.0.0",
    "stream-chat": "^8.49.0",
    "stream-chat-angular": "^5.11.0",
    "timers": "^0.1.1",
    "timers-browserify": "^2.0.12",
    "tslib": "^2.8.1",
    "uuid": "^11.0.3",
    "xml2js": "^0.6.2",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.6",
    "@angular-devkit/core": "19.0.6",
    "@angular-devkit/schematics": "19.0.6",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "~19.0.0",
    "@angular/compiler-cli": "19.0.5",
    "@angular/language-service": "19.0.5",
    "@angular/localize": "19.0.5",
    "@badeball/cypress-cucumber-preprocessor": "^21.0.3",
    "@commitlint/cli": "^19.6.1",
    "@commitlint/config-conventional": "^19.6.0",
    "@compodoc/compodoc": "^1.1.26",
    "@cypress/webpack-preprocessor": "^6.0.2",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.18.0",
    "@hey-api/openapi-ts": "^0.60.1",
    "@nestjs/schematics": "^10.2.3",
    "@nestjs/testing": "^10.4.15",
    "@ngrx/schematics": "19.0.0",
    "@nx/angular": "20.3.0",
    "@nx/cypress": "20.3.0",
    "@nx/esbuild": "20.3.0",
    "@nx/eslint": "20.3.0",
    "@nx/eslint-plugin": "20.3.0",
    "@nx/jest": "20.3.0",
    "@nx/js": "20.3.0",
    "@nx/nest": "20.3.0",
    "@nx/node": "20.3.0",
    "@nx/plugin": "20.3.0",
    "@nx/web": "20.3.0",
    "@nx/webpack": "20.3.0",
    "@nx/workspace": "20.3.0",
    "@pmmmwh/react-refresh-webpack-plugin": "^0.5.15",
    "@schematics/angular": "19.0.6",
    "@stylistic/eslint-plugin": "^2.12.1",
    "@svgr/webpack": "^8.0.1",
    "@swc-node/register": "1.10.9",
    "@swc/cli": "0.5.2",
    "@swc/core": "1.10.3",
    "@types/jasmine": "^5.1.5",
    "@types/jest": "29.5.14",
    "@types/lodash": "^4.17.13",
    "@types/luxon": "^3.4.0",
    "@types/node": "22.10.2",
    "@types/office-js": "^1.0.453",
    "@types/office-runtime": "^1.0.33",
    "@types/parse5": "^7.0.0",
    "@types/passport-azure-ad": "^4.3.6",
    "@types/passport-jwt": "^4.0.0",
    "@types/xml2js": "^0.4.12",
    "@typescript-eslint/eslint-plugin": "8.18.2",
    "@typescript-eslint/parser": "8.18.2",
    "@typescript-eslint/utils": "8.18.2",
    "angular-eslint": "^19.0.2",
    "chalk": "4.1.2",
    "codelyzer": "6.0.2",
    "cucumber-html-reporter": "^7.2.0",
    "cypress": "13.17.0",
    "esbuild": "0.24.2",
    "eslint": "9.17.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "^4.1.0",
    "eslint-plugin-deprecation": "^3.0.0",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-rxjs-angular": "^2.0.1",
    "eslint-plugin-rxjs-angular-updated": "^1.0.27",
    "eslint-plugin-rxjs-x": "^0.6.2",
    "eslint-plugin-unused-imports": "^4.1.4",
    "fkill-cli": "^8.0.0",
    "husky": "^9.1.7",
    "jasmine-core": "^5.5.0",
    "jasmine-spec-reporter": "^7.0.0",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-environment-node": "^29.7.0",
    "jest-preset-angular": "14.4.2",
    "json": "^11.0.0",
    "jsonc-eslint-parser": "^2.3.0",
    "nx": "20.3.0",
    "nx-stylelint": "^18.0.0",
    "prettier": "^3.4.2",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "prisma": "6.1.0",
    "react-refresh": "^0.16.0",
    "stylelint": "^16.12.0",
    "stylelint-config-idiomatic-order": "^10.0.0",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard": "^36.0.1",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-junit-formatter": "^0.2.2",
    "ts-jest": "^29.2.5",
    "ts-node": "10.9.2",
    "typescript": "5.6.3",
    "typescript-eslint": "^8.21.0",
    "url-loader": "^4.1.1",
    "webpack-bundle-analyzer": "^4.9.1"
  }
}
