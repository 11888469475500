import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

type OnChangeFn = (value: number | undefined) => void;

type OnTouchedFn = () => void;

/**
 *
 * Percentage input with Custom Form Controls:
 * https://blog.angular-university.io/angular-custom-form-controls/
 *
 */
@Component({
  selector: 'jw-percentage-input-ui',
  imports: [FormsModule, InputNumberModule],
  templateUrl: './percentage-input-ui.component.html',
  styleUrl: './percentage-input-ui.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PercentageInputUiComponent,
    },
  ],
})
export class PercentageInputUiComponent {
  @Input() min = 0;
  @Input() max = 100;
  @Input() minFractionDigits = 0;
  @Input() maxFractionDigits = 20;
  @Input() placeholder = '';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onInput = new EventEmitter<number>();

  //#region Standard
  /**
   * The value value of the input
   */
  value: number | undefined = undefined;

  /**
   * Callback called on change
   * We need this function to allow user to specify `formControlName` input
   * @param value number | undefined
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: number | undefined) => {};

  /**
   * Callback called on touched
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  /**
   * The input is considered to be touched by the user.
   */
  touched = false;

  /**
   * Disable status of the input
   */
  disabled = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Writes a new value to the element
   * Overriding from `ControlValueAccessor` interface
   * @param value new value number
   */
  writeValue(value: number) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * Overriding from `ControlValueAccessor` interface
   * @param onChange callback function
   */
  registerOnChange(onChange: OnChangeFn) {
    this.onChange = onChange;
  }

  /**
   * Registers a callback function that is called when the control's touched.
   * Overriding from `ControlValueAccessor` interface
   * @param onTouched callback function
   */
  registerOnTouched(onTouched: OnTouchedFn) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   * @param disabled disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  onBlurEvent() {
    if (!this.disabled) {
      this.value = this.value ?? 0;
      this.value = this.value > this.max ? this.max : this.value;
      this.value = this.value < this.min ? this.min : this.value;
      this.onChange(this.value);
      this.onBlur.emit(this.value);
    }
  }

  onInputFocus() {
    this.markAsTouched();
    this.value = this.value === 0 ? undefined : this.value;
  }
}
