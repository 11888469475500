import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  InjectionToken,
  Injector,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivationEnd, Router } from '@angular/router';
import { ObservableComponentClass } from '@joorney/utils-shared-frontend-ng-component-utils';
import { Store, createAction } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { filter, firstValueFrom, switchMap, take, takeUntil, tap } from 'rxjs';
import { StackContentComponent, StackService } from './stack.service';

export interface StackConfig<D = unknown> {
  data?: D;
  onClose: () => void;
}
export const STACK_CONFIG = new InjectionToken<StackConfig>('stack.config');
export const stackClosed = createAction('stackClosed');

@Component({
  selector: 'jw-stack',
  imports: [ButtonModule],
  templateUrl: './stack.component.html',
  styleUrl: './stack.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackComponent extends ObservableComponentClass implements OnInit, AfterViewInit {
  @Output() opened = new EventEmitter<boolean>();
  @Output() expanded = new EventEmitter<boolean>();

  readonly isOpened = toSignal(this.stackService.isOpen$.pipe(tap((isOpen) => this.opened.emit(isOpen))));
  readonly isExpanded = toSignal(this.stackService.isExpanded$.pipe(tap((isExpanded) => this.expanded.emit(isExpanded))));
  readonly expandLogo = computed(() => (this.isExpanded() ? 'assets/img/icon/sidenav/sidenav-collapse-left.svg' : 'assets/img/icon/sidenav/sidenav-collapse-right.svg'));

  @ViewChild('container', { read: ViewContainerRef }) private readonly container!: ViewContainerRef;

  constructor(
    private readonly router: Router,
    private readonly stackService: StackService,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        tap(() => this.stackService.close()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.stackService.isOpen$
      .pipe(
        filter(Boolean),
        switchMap(() => this.stackService.allowChange$()),
        filter((allowChange) => allowChange),
        switchMap(() => this.stackService.componentData$.pipe(take(1))),
        tap(() => this.container.clear()),
        filter(({ component }) => component !== null),
        tap(({ component, data }) => {
          const stack_config: StackConfig = { data, onClose: () => this.close() };
          const injector = Injector.create({
            providers: [{ provide: STACK_CONFIG, useValue: stack_config }],
          });
          const contentComponent = this.container.createComponent(component as Type<StackContentComponent>, { injector });
          this.stackService.registerContent(contentComponent.instance);
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.stackService.isOpen$
      .pipe(
        filter((isOpen) => !isOpen),
        tap(() => this.container.clear()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  toggleExpand() {
    this.stackService.toggleExpand();
  }

  close() {
    this.stackService
      .allowChange$()
      .pipe(
        filter((allowChange) => allowChange),
        tap(() => {
          this.store.dispatch(stackClosed());
          this.stackService.close();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  // warn user when a stack with pending changes is open before closing the tab
  @HostListener('window:beforeunload', ['$event'])
  private async onBeforeUnload($event: BeforeUnloadEvent) {
    if (await firstValueFrom(this.stackService.allowChange$(false))) {
      return;
    }

    $event.preventDefault();
  }
}
