import { BusinessPlanApplicantDTO, BusinessPlanByIdDTO, BusinessPlanFeatureConfigurationDTO, BusinessPlanVersionsDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { WrikeProjectGetDTO } from '@joorney/wrike-jdata-wrike-api-wrike-project-feature';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CONTEXT_FEATURE_KEY = 'context';

export const contextActions = createActionGroup({
  source: CONTEXT_FEATURE_KEY,
  events: {
    initCurrentBusinessPlanFinished: props<{ businessPlan: BusinessPlanByIdDTO & { lastBPVersionId: number; lastBPVersionName: string } }>(),
    initBusinessPlanVersionsFinished: props<{ businessPlanVersions: BusinessPlanVersionsDTO[] }>(),
    initCurrentBusinessPlanFailed: emptyProps(),
    businessPlanLeft: emptyProps(),
    setCurrentBusinessPlanYearLabels: props<{ yearLabels: string[] }>(),
    initWithBusinessPlanId: props<{ businessPlanId: number }>(),
    initBusinessPlanFromRouteParam: emptyProps(),
    addNewBusinessPlanVersion: props<{ businessPlanId: number; businessPlanSaveName: string; businessPlanSaveNote: string }>(),
    updateBusinessPlanVersion: props<{ businessPlanId: number; businessPlanSaveName: string; businessPlanSaveNote: string }>(),
    businessPlanApplicantsReceived: props<{ applicants: BusinessPlanApplicantDTO[] }>(),
    businessPlanFeatureConfigurationReceived: props<{ featureConfiguration: BusinessPlanFeatureConfigurationDTO }>(),
    businessPlanWrikeProjectReceived: props<{ wrikeProject: WrikeProjectGetDTO }>(),
    businessPlanVisaTypeChanged: props<{ newVisaTypeId: string }>(),
    businessPlanVisaTypeChangeConfirmed: props<{ newVisaTypeId: string }>(),
    businessPlanVisaTypeChangeDeclined: emptyProps(),
    businessPlanVisaTypeChangeFinished: props<{ versionId: number }>(),
    businessPlanModified: emptyProps(),
    currentBusinessPlanUnselected: emptyProps(),
    payrollTaxRateEdited: emptyProps(),
    payrollTaxRateSaveClicked: props<{ data: number }>(),
    payrollTaxRateSaveFinished: emptyProps(),
    payrollTaxRateSaveFailed: emptyProps(),
  },
});
