import { Injectable } from '@angular/core';
import {
  businessPlanProjectFeatureControllerArchive,
  businessPlanProjectFeatureControllerGetBusinessPlanListByProjectId,
  businessPlanProjectFeatureControllerGetById,
  businessPlanProjectFeatureControllerGetList,
  businessPlanProjectFeatureControllerGetListForUser,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { DateTime } from 'luxon';
import { from } from 'rxjs';

export interface BusinessPlanPaginationParams {
  page?: number;
  itemPerPage?: number;
  searchTerm?: string;
  visaType?: string;
  companyId?: number;
  creatorId?: number;
  createdBy?: string;
  creationDate?: Date;
}

export const DEFAULT_ITEMS_PER_PAGE = 100;

@Injectable({ providedIn: 'root' })
export class BusinessPlanProjectDataAccessService {
  getBusinessPlanProjectList(queryParams: BusinessPlanPaginationParams) {
    return from(
      businessPlanProjectFeatureControllerGetList({
        page: queryParams.page,
        itemPerPage: queryParams.itemPerPage ?? DEFAULT_ITEMS_PER_PAGE,
        searchTerm: queryParams.searchTerm || undefined,
        visaType: queryParams.visaType || undefined,
        companyId: queryParams.companyId ?? undefined,
        creatorId: queryParams.creatorId ?? undefined,
        createdBy: queryParams.createdBy || undefined,
        creationDate: queryParams.creationDate ? DateTime.fromJSDate(queryParams.creationDate).toFormat('yyyy-MM-dd') : undefined,
      }),
    ).pipe(throwErrorOnInvalidValue());
  }

  getMyBusinessPlanProjectList(queryParams: BusinessPlanPaginationParams) {
    return from(
      businessPlanProjectFeatureControllerGetListForUser({
        page: queryParams.page,
        itemPerPage: queryParams.itemPerPage ?? DEFAULT_ITEMS_PER_PAGE,
        searchTerm: queryParams.searchTerm || undefined,
        visaType: queryParams.visaType || undefined,
        companyId: queryParams.companyId ?? undefined,
      }),
    ).pipe(throwErrorOnInvalidValue());
  }

  getBusinessPlanListByProjectId(projectId: number) {
    return from(businessPlanProjectFeatureControllerGetBusinessPlanListByProjectId({ projectId })).pipe(throwErrorOnInvalidValue());
  }

  getBusinessPlanProjectById(projectId: number) {
    return from(businessPlanProjectFeatureControllerGetById({ projectId })).pipe(throwErrorOnInvalidValue());
  }

  archive(projectId: number) {
    return from(businessPlanProjectFeatureControllerArchive({ projectId })).pipe(throwErrorOnInvalidValue());
  }
}
