import {
  GeneratedContentBalanceSheet,
  GeneratedContentBalanceSheetYearAssetsLongTermAssets,
  GeneratedContentBalanceSheetYearCapital,
  GeneratedContentBalanceSheetYearLiabilities,
  GeneratedContentData,
  JwTableRowClass,
} from '@joorney/computation-shared-frontend-sections-domain';
import * as _ from 'lodash';

export enum BalanceSheetLabel {
  Assets = 'ASSETS',
  CurrentAssets = 'CURRENT ASSETS',
  CashAndOtherCurrentAssets = 'CASH AND OTHER CURRENT ASSETS',
  AccountsReceivable = 'ACCOUNTS RECEIVABLE',
  TotalCurrentAssets = 'TOTAL CURRENT ASSETS',
  LongTermAssets = 'LONG-TERM ASSETS',
  AccumulatedDepreciation = 'ACCUMULATED DEPRECIATION',
  TotalLongTermAssets = 'TOTAL LONG-TERM ASSETS',
  TotalAssets = 'TOTAL ASSETS',
  LiabilitiesAndCapital = 'LIABILITIES AND CAPITAL',
  AccountsPayable = 'ACCOUNTS PAYABLE',
  CurrentLiabilities = 'CURRENT LIABILITIES',
  LongTermLiabilities = 'LONG-TERM LIABILITIES',
  TotalLiabilities = 'TOTAL LIABILITIES',
  Capital = 'CAPITAL',
  PaidInCapital = 'PAID-IN CAPITAL',
  RetainedEarnings = 'RETAINED EARNINGS',
  Earnings = 'EARNINGS',
  TotalCapital = 'TOTAL CAPITAL',
  NetWorth = 'NET WORTH',
  TotalLiabilitiesAndCapital = 'TOTAL LIABILITIES AND CAPITAL',
}

/**
 * Check if there is at least one long terme assets
 * @param longTermAssets Array of GeneratedContentBalanceSheetYearAssetsLongTermAssets
 * @returns boolean
 */
const hasLongTermAssets = (longTermAssets: (GeneratedContentBalanceSheetYearAssetsLongTermAssets | undefined)[]): boolean => {
  return _.some(longTermAssets, (asset) => !_.isEmpty(asset));
};

/**
 * Check if there is at least one liability
 * @param liabilities GeneratedContentBalanceSheetYearLiabilities
 * @returns boolean
 */
const hasLiabilities = (liabilities: GeneratedContentBalanceSheetYearLiabilities | undefined) => {
  return _.some(liabilities, (value) => value !== 0);
};

/**
 * Check if there is at least one capital
 * @param capital GeneratedContentBalanceSheetYearCapital
 * @returns boolean
 */
const hasCapital = (capital: GeneratedContentBalanceSheetYearCapital | undefined): boolean => {
  return _.some(capital, (value) => value !== 0);
};

export const transformBalanceSheetData = (data: GeneratedContentBalanceSheet, yearLabels: string[]): GeneratedContentData[] => {
  const cashAndOtherCurrentAssets = data.year.map((d) => d.assets?.currentAssets?.cashAndOtherCurrentAssets);
  const accountReceivables = data.year.map((d) => d.assets?.currentAssets?.accountReceivable);
  const totalCurrentAssets = data.year.map((d) => d.assets?.currentAssets?.totalCurrentAssets);
  const longTermAssets = data.year.map((d) => d.assets?.longTermAssets);
  const longTermAssetsvalues = data.year.map((d) => d.assets?.longTermAssets?.longTermAssetsAmount);
  const accumulatedDepreciations = data.year.map((d) => d.assets?.longTermAssets?.accumulatedDepreciation);
  const totalLongTermAssets = data.year.map((d) => d.assets?.longTermAssets?.totalLongTermAssets);
  const totalAssets = data.year.map((d) => d.assets?.totalAssets);
  const accountPayables = data.year.map((d) => d.liabilities?.accountPayable);
  const currentLiabilities = data.year.map((d) => d.liabilities?.currentLiabilities);
  const longTermLiabilities = data.year.map((d) => d.liabilities?.longTermLiabilities);
  const totalLiabilities = data.year.map((d) => d.liabilities?.totalLiabilities);
  const paidinCapitals = data.year.map((d) => d.capital?.paidinCapital);
  const retainedEarnings = data.year.map((d) => d.capital?.retainedEarnings);
  const earnings = data.year.map((d) => d.capital?.earnings);
  const totalCapitals = data.year.map((d) => d.capital?.totalCapital);
  const totalLiabilitiesAndCapitals = data.year.map((d) => d.totalLiabilitiesAndCapital);

  const balanceSheet: GeneratedContentData[] = [
    {
      label: 'Assets',
      values: [],
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    },
    {
      label: 'Current Assets',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
    },
    {
      label: 'Cash and Other Current Assets',
      values: [],
      hide: !cashAndOtherCurrentAssets.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Accounts Receivable',
      values: [],
      hide: !accountReceivables.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Current Assets',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalCurrentAssets.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Long-Term Assets',
      values: [],
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading],
      hide: !hasLongTermAssets(longTermAssets),
      isCurrency: true,
    },
    {
      label: 'Long-Term Assets',
      values: [],
      hide: !longTermAssetsvalues.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Accumulated Depreciation',
      values: [],
      hide: !accumulatedDepreciations.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Long-Term Assets',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalLongTermAssets.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Assets',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalAssets.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Liabilities and Capital',
      values: yearLabels,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
      hide: !hasLiabilities(data.year[0].liabilities) && hasCapital(data.year[0].capital),
    },
    {
      label: 'Current Liabilities',
      values: [],
      hide: !currentLiabilities.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Accounts Payable',
      values: [],
      hide: !accountPayables.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Long-Term Liabilities',
      values: [],
      hide: !longTermLiabilities.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Liabilities',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalLiabilities.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Capital',
      values: yearLabels,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
      hide: !(hasCapital(data.year[0].capital) && !hasLiabilities(data.year[0].liabilities)),
    },
    {
      label: 'Paid-In Capital',
      values: [],
      hide: !paidinCapitals.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Retained Earnings',
      values: [],
      hide: !retainedEarnings.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Earnings',
      values: [],
      hide: !earnings.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Capital',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalCapitals.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Liabilities and Capital',
      values: [],
      styleClass: [JwTableRowClass.TableHeading],
      hide: !totalLiabilitiesAndCapitals.some((data) => data) || !hasLiabilities(data.year[0].liabilities) || !hasCapital(data.year[0].capital),
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Net Worth',
      values: [],
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
      hide: !totalCapitals.some((data) => data),
      isCurrency: true,
      noDigits: true,
    },
  ];

  // Fill years' value
  balanceSheet.forEach((bs) => {
    data.year.forEach((y) => {
      switch (bs.label.toUpperCase() as BalanceSheetLabel) {
        case BalanceSheetLabel.Assets:
          (bs.values ?? []).push(null);
          break;

        case BalanceSheetLabel.CurrentAssets:
          (bs.values ?? []).push(null);
          break;

        case BalanceSheetLabel.CashAndOtherCurrentAssets:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.currentAssets?.cashAndOtherCurrentAssets);
          }
          break;

        case BalanceSheetLabel.AccountsReceivable:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.currentAssets?.accountReceivable);
          }
          break;

        case BalanceSheetLabel.TotalCurrentAssets:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.currentAssets?.totalCurrentAssets);
          }
          break;

        case BalanceSheetLabel.LongTermAssets:
          if (bs.isHeading) {
            (bs.values ?? []).push(null);
          } else {
            if (!bs.hide) {
              (bs.values ?? []).push(y.assets?.longTermAssets?.longTermAssetsAmount);
            }
          }
          break;

        case BalanceSheetLabel.AccumulatedDepreciation:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.longTermAssets?.accumulatedDepreciation);
          }
          break;

        case BalanceSheetLabel.TotalLongTermAssets:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.longTermAssets?.totalLongTermAssets);
          }
          break;

        case BalanceSheetLabel.TotalAssets:
          if (!bs.hide) {
            (bs.values ?? []).push(y.assets?.totalAssets);
          }
          break;

        case BalanceSheetLabel.AccountsPayable:
          if (!bs.hide) {
            (bs.values ?? []).push(y.liabilities?.accountPayable);
          }
          break;

        case BalanceSheetLabel.CurrentLiabilities:
          if (!bs.hide) {
            (bs.values ?? []).push(y.liabilities?.currentLiabilities);
          }
          break;

        case BalanceSheetLabel.LongTermLiabilities:
          if (!bs.hide) {
            (bs.values ?? []).push(y.liabilities?.longTermLiabilities);
          }
          break;

        case BalanceSheetLabel.TotalLiabilities:
          if (!bs.hide) {
            (bs.values ?? []).push(y.liabilities?.totalLiabilities);
          }
          break;

        case BalanceSheetLabel.PaidInCapital:
          if (!bs.hide) {
            (bs.values ?? []).push(y.capital?.paidinCapital);
          }
          break;

        case BalanceSheetLabel.RetainedEarnings:
          if (!bs.hide) {
            (bs.values ?? []).push(y.capital?.retainedEarnings);
          }
          break;

        case BalanceSheetLabel.Earnings:
          if (!bs.hide) {
            (bs.values ?? []).push(y.capital?.earnings);
          }
          break;

        case BalanceSheetLabel.NetWorth:
        case BalanceSheetLabel.TotalCapital:
          if (!bs.hide) {
            (bs.values ?? []).push(y.capital?.totalCapital);
          }
          break;

        case BalanceSheetLabel.TotalLiabilitiesAndCapital:
          if (!bs.hide) {
            (bs.values ?? []).push(y.totalLiabilitiesAndCapital);
          }
          break;
      }
    });
  });

  return balanceSheet.filter((el) => el.values?.some((v) => v !== 0));
};
