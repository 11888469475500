import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class ObservableComponentClass implements OnDestroy {
  // eslint-disable-next-line rxjs-x/no-exposed-subjects
  protected destroyed$ = new ReplaySubject<void>(1);
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
